import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import DesignNDevImg from  "../img/Design_and_Development.png";
const Services = () => {
  const [hoverIndex, setHoverIndex] = useState(-1);  // State to track hovered item index

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  return (
    <div id="Services" className="bg-f2f8fb our-mission-area pt-32" data-aos="fade-up" data-aos-duration="800">
      <div className="container mx-auto px-4 overflow-hidden"> {/* Ensuring no overflow */}
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/2 px-2 py-4" data-aos="fade-right" data-aos-duration="900" data-aos-delay="100">
            <div className="our-mission-content bg-white p-6" data-aos="zoom-in" data-aos-duration="900" data-aos-delay="200">
              <div className="content">
                <span className="sub-title block uppercase mb-3 text-orange-600 font-bold text-sm">
                  <img
                    alt="star-icon"
                    loading="lazy"
                    width="32"
                    height="34"
                    className="inline-block align-middle mr-2"
                    src="https://tarn-react.envytheme.com/img/star-icon.png"
                  />
                  Services
                </span>
                <h2 className="font-bold text-4xl mb-4" data-aos="fade-down" data-aos-duration="900" data-aos-delay="300">Design & Development</h2>
                <p className="mb-6" data-aos="fade-up" data-aos-duration="900" data-aos-delay="400">
                Discover our comprehensive suite of services designed to propel your business forward. From custom software development to expert consultancy, we offer solutions tailored to your needs.
                </p>
                <div className="flex flex-wrap -mx-4">
                  {[
                    "Responsive Design",
                    "Graphics Designing",
                    
                    "Mobile Apps Development",
                    "Full Stack Development",
                    "UX/UI Design",
                    "E-commerce Design",
                    "Web Design",
                    "Data Analytics and AI",
                    "WordPress Development",
                    "React Website Development"
                  ].map((item, index) => (
                    <div 
                      key={index}
                      className="p-2 w-full lg:w-1/2"
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(-1)}
                      data-aos="flip-left"
                      data-aos-duration="900"
                      data-aos-delay={`${500 + index * 100}`}  // Delay increases with each card
                    >
                      <div className="bg-white p-5 pl-20 relative rounded-lg shadow-md text-lg font-bold">
                        <span 
                          className={`absolute left-5 top-1/2 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center rounded-lg text-xl transition duration-900 ease-in-out ${
                            hoverIndex === index ? "bg-orange-500 text-white" : "bg-blue-100"
                          }`}
                        >
                          ✓
                        </span>
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-2 py-4" data-aos="fade-left" data-aos-duration="900" data-aos-delay="600">
            <div className="our-mission-image" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
              <img
                alt="service2"
                loading="lazy"
                width="710"
                height="620"
                className="max-w-full h-auto"
                //src="https://tarn-react.envytheme.com/img/services/service2.png"

                src={DesignNDevImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;