import React, {useEffect} from "react";
import bannerBg from "../img/banner-bg.png";
import bannerMain from  "../img/banner-main.png";
import "./HeaderStyles.css";
import { useNavigate } from 'react-router-dom';
function Header() {
  
   useEffect(() => {
    
      window.scrollTo(0, 0);
      
    
    
  });

  const navigat = useNavigate();
  const handleContactUs = () => {
    navigat('/ContactUs');
    
    
  };
  return (
    <div
      id="Home"
      className="min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <div id="banner">
        <div className="flex flex-col bg-white bg-opacity-0 px-6 lg:mt-16 md:pt-12 sm:pt-12 pt-16">
          <div className="container mx-auto px-6 pb-8 lg:pt-8">
            <div className="flex flex-col lg:flex-row items-center ">
              <div className="lg:w-1/2 w-full mb-8 lg:mb-0 lg:pl-12">
                <div className="text-left text-black">
                  <h1
                    className="text-3xl text-black sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="100"
                  >
                    Inovative Software Solutions for a Dynamic World
                  </h1>
                  <p
                    className="text-base sm:text-lg mb-6"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="200"
                  >
                    Elevate your digital presence with our bespoke software solutions. Our seasoned team transforms ideas into reality, ensuring your success in today's dynamic landscape. From startups to established enterprises, we're here to innovate and propel you forward. Explore our services and redefine what's possible with us
                  </p>
                  <div
                    className="btn-box aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="300"
                  >
                    <button
                      className="default-btn bg-orange-500 text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md transition transform hover:scale-105 hover:bg-orange-600 inline-block"
                      onClick={handleContactUs}
                    >
                      <i className="flaticon-right mr-2"></i>Get Started
                    </button>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 w-full mt-8 lg:mt-0 lg:pl-12">
                <div
                  className="aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="400"
                >
                  <img
                    alt="banner"
                    className=" w-full sm:w-2/3 md:w-1/2 lg:w-full h-auto max-w-xs sm:max-w-md md:max-w-md lg:max-w-xl mx-auto"
                    // src="https://tarn-react.envytheme.com/img/banner-img1.png"
                    src={bannerMain}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col mt-12 justify-center items-center text-center text-white h-full py-20 px-4"> */}
    </div>
   
  );
}

export default Header;
