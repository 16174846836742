import React from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
// import logo from "../img/Logo-text-blue.png";
// import logo from "../img/DMZLogoBlue.png";
import logo from '../img/DMZLogoii.png';
import logotext from '../img/DMZlogoFonts .png';
// import logotext from "../img/Logo-text-blue.png";
const Footer = () => {
  const navigate = useNavigate();
  const navigatee = useNavigate();
  const navigateee = useNavigate();

  const handleMenuItemClick = () => {
    navigate("/");
  };
  const handleContactUs = () => {
    navigatee("/ContactUs");
  };
  // const handlePrivacyPage = () => {
  //   navigateee("/PrivacyPage");
  // };
  const handlePrivacyPage = (e) => {
    // Check if the current pathname is '/privacypage'
    if (window.location.pathname === '/PrivacyPage') {
      e.preventDefault(); // Prevent the default navigation behavior
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
    else {
      navigateee("/PrivacyPage");
    }
  };

  return (
    <footer className=" bg-fefcf8 py-8 px-4">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 xl:px-40 z-10">
        <div className="flex flex-wrap md:grid md:grid-cols-2 lg:flex lg:justify-between space-y-8 ">
          {/* Column 1 */}

          <div className="w-full md:w-1/2 lg:w-1/4 mt-6 mr-8">
            <div className="logo flex cursor-pointer pb-4">
            <img src={logo} alt="Logo" className="h-12 md:h-[70px]" />
            <img src={logotext} alt="Logo Text" className="h-12 md:h-[65px] mt-0 ml-[-0.875rem]" />
            </div>
            {/* <img
              // src="https://tarn-react.envytheme.com/img/logo.png"
              src={logo}
              alt="Company Logo"
              className="w-32 mb-6 h-12 w-auto"
            /> */}
            <p className="mb-1 text-base text-gray-600 font-semibold md:text-base">
              At DMZ Dev Hub, we're dedicated to crafting innovative solutions
              that drive success. With a focus on excellence and client
              satisfaction, we're committed to delivering results that exceed
              expectations. Let's build something remarkable together.
            </p>
            <div className="flex space-x-4 mb-4">
              <a
                href="https://www.facebook.com/profile.php?id=61561125106815"
                className="text-gray-600 hover:text-gray-900 text-lg"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/dmz.dev.hub?igsh=MXM4emhmYW5zNmJ0bA=="
                className="text-gray-600 hover:text-gray-900 text-lg"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/101558892/"
                className="text-gray-600 hover:text-gray-900 text-lg"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>

          {/* Column 2 */}
          <div className="w-full md:w-1/2 lg:w-1/5">
            <h4 className="text-lg text-gray-800 font-nunito font-bold mb-4 md:text-lg">
              Explore
            </h4>
            <ul>
              <li className="mb-1 ">
                <Link
                  to="Home"
                  smooth={true}
                  duration={500}
                  onClick={handleMenuItemClick}
                  className="relative text-base text-gray-600 hover:text-orange-500 hover:underline md:text-base cursor-pointer"
                >
                  Home
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  to="Services"
                  smooth={true}
                  duration={500}
                  className="block text-base text-gray-600 hover:text-orange-500 hover:underline md:text-base cursor-pointer"
                >
                  Services
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  to="Team"
                  smooth={true}
                  duration={500}
                  className="block text-base text-gray-600 hover:text-orange-500 hover:underline md:text-base cursor-pointer"
                >
                  Team
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  to="Projects"
                  smooth={true}
                  duration={500}
                  className="block text-base text-gray-600 hover:text-orange-500 hover:underline md:text-base cursor-pointer"
                >
                  Projects
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  to="contactus"
                  smooth={true}
                  duration={500}
                  onClick={handleContactUs}
                  className="block text-base text-gray-600 hover:text-orange-500 hover:underline md:text-base cursor-pointer"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 3 */}
          {/* <div className="w-full md:w-1/2 lg:w-1/5 mr-2">
            <h4 className="text-lg text-gray-800 font-nunito font-bold mb-4 md:text-lg">
              Resources
            </h4>
            <ul>
              <li className="mb-1">
                <a
                  href="#blog"
                  className="text-base text-gray-600 hover:underline md:text-base"
                >
                  Our Scientists
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="#guides"
                  className="text-base text-gray-600 hover:underline md:text-base"
                >
                  Our Services
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="#tools"
                  className="text-base text-gray-600 hover:underline md:text-base"
                >
                  Testimonials
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="#faq"
                  className="text-base text-gray-600 hover:underline md:text-base"
                >
                  SaaS Solutions
                </a>
              </li>
              <li className="mb-1">
                <a
                  href="#support"
                  className="text-base text-gray-600 hover:underline md:text-base"
                >
                  Case Studies
                </a>
              </li>
            </ul>
          </div> */}

          {/* Column 4 */}
          <div className="w-full md:w-1/2 lg:w-1/4">
            <h4 className="text-gray-800 font-nunito font-bold mb-4 text-lg md:text-lg">
              Address
            </h4>
            <p className="mb-1 text-base text-gray-600 font-semibold md:text-base">
              2/3 4, Greenbank Street Glasgow, Scotland G73 1JU
            </p>
            <a
              href="https://wa.me/447465655152"
              target="_blank"
              rel="noopener noreferrer"
              className="block mb-4 text-base text-gray-600 font-semibold md:text-base hover:text-orange-500 hover:underline flex items-center"
            >
              <i className="fab fa-whatsapp mr-2"></i> +447465655152
            </a>

            <a
              href="mailto:info@dmzdevzone.com"
              className="block text-base text-gray-600 font-semibold md:text-base hover:text-orange-500 hover:underline flex items-center"
            >
              <i className="fas fa-envelope mr-2"></i> info@dmzdevzone.com
            </a>


            {/* <p className="mb-1 text-base text-gray-600 font-semibold md:text-base">
              +92 3093314068
            </p> */}
          </div>
        </div>
        <div className="mt-8 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-gray-600 font-nunito text-sm md:text-base mb-2 sm:mb-0">
            &copy; Copyright ©2024 <span className="font-bold">DMZ Dev Hub.</span>{" "}
            All Rights Reserved.
          </p>

          <Link
            to="/PrivacyPage"
            smooth={true}
            duration={500}
            onClick={handlePrivacyPage}
            className="text-gray-600 hover:text-orange-500 mb-2 font-nunito text-sm md:text-base hover:underline cursor-pointer"
          >
            Privacy Policy | Terms & Conditions
          </Link>
        </div>
      </div>
      {/* <div
    className="hidden md:block inset-0 m-auto h-2/3 w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4 bg-contain bg-no-repeat bg-center animate-move"
    style={{
      backgroundImage: `url('https://tarn-react.envytheme.com/img/footer-map.png')`,
    }}
  ></div> */}
    </footer>
  );
};

export default Footer;
