
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
// import logo from '../img/DMZLogoBlue.png';
// import logo from '../img/DMZLogo.png'
import logo from '../img/DMZLogoii.png';
import logotext from '../img/DMZlogoFonts .png';
// import logo from '../img/Logo.png';
// import logotext from '../img/Logo-text-blue.png';
// import logotext from '../img/Logo-text.png';

const Navbar = () => {
  const [colorChange, setColorChange] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);

  const handleLogoClick = () => {
    navigate('/');
  };

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      duration: 500,
      offset: -80 // Adjust the offset value to match your fixed navbar height
    });
  };

  const handleMenuItemClick = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scrollToSection(section);
      }, 100); // Adjust the timeout duration if needed
    } else {
      scrollToSection(section);
    }
    setIsMobileMenuOpen(false);
  };

  const handleContactUsClick = () => {
    navigate('/ContactUs');
    setIsMobileMenuOpen(false);
  };
  const handleBlogPageClick = () => {
    navigate('/BlogPage');
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className={`flex items-center justify-between fixed w-full z-10 px-[50px] py-4 text-[17px] font-bold transition-colors duration-300 ease-in-out ${colorChange ? 'bg-white shadow-lg' : 'bg-transparent shadow-none'} font-nunito`}>
      <div className="logo flex cursor-pointer" onClick={handleLogoClick}>
      <img src={logo} alt="Logo" className="h-12 md:h-[70px]" />
<img src={logotext} alt="Logo Text" className="h-12 md:h-[65px] mt-0 ml-[-0.875rem]" />


      </div>
      <button className="text-3xl text-orange-500 mobile-menu-button md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
        {isMobileMenuOpen ? '\u2715' : '\u2630'}
      </button>
      <ul className={`flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-12 flex-grow md:static absolute w-full left-0 bg-white md:bg-transparent transition-all duration-300 ease-in ${isMobileMenuOpen ? 'top-16' : 'top-[-490px]'}`}>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="Home" smooth={true} duration={500} onClick={() => handleMenuItemClick('Home')} className="block text-current no-underline cursor-pointer">Home</ScrollLink>
        </li>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="Services" smooth={true} duration={500} onClick={() => handleMenuItemClick('Services')} className="block text-current no-underline cursor-pointer">Services</ScrollLink>
        </li>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="Team" smooth={true} duration={500} onClick={() => handleMenuItemClick('Team')} className="block text-current no-underline cursor-pointer">Team</ScrollLink>
        </li>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="Projects" smooth={true} duration={500} onClick={() => handleMenuItemClick('Projects')} className="block text-current no-underline cursor-pointer">Projects</ScrollLink>
        </li>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="blogpage" smooth={true} duration={500} onClick={handleBlogPageClick} className="block text-current no-underline cursor-pointer">Blog</ScrollLink>
        </li>
        <li className="hover:text-orange-500 transition-colors duration-300 py-2 mx-3.5">
          <ScrollLink to="contactus" smooth={true} duration={500} onClick={handleContactUsClick} className="block text-current no-underline cursor-pointer">Contact Us</ScrollLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;