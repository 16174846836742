import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComputer,
  // faCheck,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

//json for projects
import carClickJson from "./Project_Data/CarClick-web.json";
import sloyedWebJson from "./Project_Data/Sloyed-web.json";
import conxtsWebJson from "./Project_Data/CONXTS-web.json";
import exhaleRehabWebJson from "./Project_Data/ExhaleRehab-web.json";
import conxtsMobileJson from "./Project_Data/CONTXTS-mobile.json";
import conxtsUiUxJson from "./Project_Data/CONXTS-uiux.json";
import sloyedUiUxJson from "./Project_Data/Sloyed-uiux.json";
import exhaleRehabUiUxJson from "./Project_Data/ExhaleRehab-uiux.json";
import digidexUiUxJson from "./Project_Data/Digidex-uiux.json";
import digidexMobileJson from "./Project_Data/Digidex-mobile.json";
import hiiveMobileJson from "./Project_Data/Hiive-mobile.json";
import hiiveUiUxJson from "./Project_Data/Hiive-uiux.json";
import  RunovalJson from "./Project_Data/Runoval.json";
import gifteeUiUxJson from "./Project_Data/Giftee-uiux.json";
import totUiUxJson from "./Project_Data/TOT-uiux.json";
import lauraUiUxJson from "./Project_Data/Laura-uiux.json";
import medRecordsUiUxJson from "./Project_Data/MedRecords-uiux.json";
import symphonyUiUxJson from "./Project_Data/Symphony-uiux.json";
import giglUiUxJson from "./Project_Data/Gigl-uiux.json";
import eeplUiUxJson from "./Project_Data/EEPL-mobile.json";
//images for projects
 import carClickImg from "../img/CarClick1.png";
 import sloyedWebImg from "../img/Sloyed1.png";
 import conxtsWebImg from "../img/CONXTS1.png";
import exhaleRehabWebImg from "../img/ExhaleRehab1.png";
 import conxtsMobileImg from "../img/CONTXTSmobile1.png";
import conxtsUiUxImg from "../img/CONXTSuiux1.png";
import sloyedUiUxImg from "../img/SloyedUiUx1.png";
import exhaleRehabUiUxImg from "../img/ExhaleRehabuiux1.png";
import digidexUiUxImg from "../img/Digidex1.png";
// import digidexMobileImg from "../img/Digidex1.png";
import hiiveMobileImg from "../img/Hiive1.png";
// import hiiveUiUxImg from "../img/Hiive1.png";
import runovalImg  from "../img/noval.jpeg";
import gifteeUiUxImg from "../img/Giftee1.png";
import totUiUxImg from "../img/TOT1.png";
import lauraUiUxImg from "../img/Laura1.png";
import medRecordsUiUxImg from "../img/MedRecords1.png";
import symphonyUiUxImg from "../img/Symphony1.png";
import giglUiUxImg from "../img/Gigl1.png";
// import eeplUiUxImg from "../img/EEPL1.png";



const projectDataMap = {
  "web-project-CarClick": carClickJson,
  "web-project-CONXTS":conxtsWebJson,
  "web-project-Sloyed":sloyedWebJson,
  "web-project-ExhaleRehab": exhaleRehabWebJson,
  "mobile-app-CONXTS":conxtsMobileJson,
  "ui-ux-CONXTS":conxtsUiUxJson,
  "ui-ux-Sloyed":sloyedUiUxJson,
  "ui-ux-ExhaleRehab":exhaleRehabUiUxJson,
  "ui-ux-Digidex":digidexUiUxJson,
  "mobile-app-Digidex": digidexMobileJson,
  "mobile-app-Hiive":hiiveMobileJson,
  "ui-ux-Hiive":hiiveUiUxJson,
  "mobile-app-Giftee":RunovalJson,
  "ui-ux-Giftee":gifteeUiUxJson,
  "ui-ux-TOT":totUiUxJson,
  "ui-ux-Laura":lauraUiUxJson,
  "ui-ux-MedRecords":medRecordsUiUxJson,
  "ui-ux-Symphony":symphonyUiUxJson,
  "ui-ux-Gigl":giglUiUxJson,
  "mobile-app-EEPL":eeplUiUxJson,
};
const ImgMap = {
  "web-project-CarClick": carClickImg,
  "web-project-CONXTS":conxtsWebImg,
  "web-project-Sloyed":sloyedWebImg,
  "web-project-ExhaleRehab": exhaleRehabWebImg,
  "mobile-app-CONXTS":conxtsMobileImg,
  "ui-ux-CONXTS":conxtsUiUxImg,
  "ui-ux-Sloyed":sloyedUiUxImg,
  "ui-ux-ExhaleRehab":exhaleRehabUiUxImg,
  "ui-ux-Digidex":digidexUiUxImg,
  "mobile-app-Digidex": digidexUiUxImg,
  "mobile-app-Hiive":hiiveMobileImg,
  "ui-ux-Hiive":hiiveMobileImg,
  "mobile-app-Giftee":runovalImg,
  "ui-ux-Giftee":gifteeUiUxImg,
  "ui-ux-TOT":totUiUxImg,
  "ui-ux-Laura":lauraUiUxImg,
  "ui-ux-MedRecords":medRecordsUiUxImg,
  "ui-ux-Symphony":symphonyUiUxImg,
  "ui-ux-Gigl":giglUiUxImg,
  "mobile-app-EEPL":giglUiUxImg,


};


// const caseStudyData = {
//   projectName: "Data Science",
//   projectTitle: "Complete Data Science Training: Mathematics, Statistics, Python, Advanced Statistics in Python",
//   paragraph1: "Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross-validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.",
//   paragraph2: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.",
//   paragraph3: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text.",
//   importantFacts: [
//     "The Field of Data Science",
//     "The Problem",
//     "The Solution",
//     "The Skills",
//     "Statistics",
//     "Mathematics"
//   ],
//   result: "Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.",
//   technologies: "Python, Data Science",
//   completed: "28 April 2023",
//   website: "EnvyTheme.com"
// };

const CaseStudyPage = () => {
  const [imageWidthClass, setImageWidthClass] = useState("w-full");
  
  const handleImageLoad = (event) => {
    const imageHeight = event.target.clientHeight;
    if (imageHeight > 615) { // You can adjust the height threshold as needed
      setImageWidthClass("w-3/4");
    } else {
      setImageWidthClass("w-full");
    }
  };
  //const data = caseStudyData;
  const { projectId } = useParams();
  const [caseStudyData, setCaseStudyData] = useState({});
  const [caseStudyImg, setCaseStudyImg] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    const projectData = projectDataMap[projectId];
    const projectImg = ImgMap[projectId];
    console.log("Loaded project data:", projectData); // Check what data is being loaded
    console.log("Loaded Image path:",projectImg);
    setCaseStudyData(projectData);
    setCaseStudyImg(projectImg);
  }, [projectId]);

  if (!caseStudyData || Object.keys(caseStudyData).length === 0) {
    return <div>Loading or no data available...</div>;
  }

  

  return (
    
    <div className="flex flex-col bg-gray-50">
      <div className="ml-16 py-32">
        <div className="page-title-content flex center justify-between items-center">
          <div className="ml-4">
            <strong className="text-bold text-5xl pt-6">
              Case Study Details
            </strong>
            <nav className="text-[16px] font-nunito font-semibold ml-4 pt-4 mb-4">
              <a href="/" className="text-gray-500 hover:text-ff5d22">
                Home
              </a>{" "}
              <i className="fas fa-chevron-right text-ff5d22 mx-2"></i>{" "}
              <span className="text-gray-900">Service Details</span>
            </nav>
          </div>
          <div className="shape-img2 pr-12">
            <img
              alt="shape2"
              loading="lazy"
              width="202"
              height="202"
              decoding="async"
              src="https://tarn-react.envytheme.com/img/shape/shape2.png"
              style={{ opacity: 0.3 }}
              className="opacity-30 animate-spin-vertical"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col ml-8 lg:flex-row">
        <div className="md:flex-1 text-black pl-8 pr-8 mb-16">
          <div className="mb-8 mr-8">
            
            <img
              // src={caseStudyData.image} // Assuming there is an image link in the JSON
              src={caseStudyImg}
              alt="Detailed View"
              // className="w-full h-auto"
              className={`${imageWidthClass} h-auto`}
              onLoad={handleImageLoad}
            />
          </div>
          <div className="case-studies-details-desc">
            <h2 className="pb-4 text-orange-500 text-xl font-bold">
              {caseStudyData.projectName}
            </h2>
            <strong className="text-black text-3xl">
              {caseStudyData.projectTitle}
            </strong>
            <p className="pt-6 pb-6 text-black text-semibold">
              {caseStudyData.paragraph1}
            </p>
            <div className="flex flex-wrap">
              {/* <div className="w-full md:w-1/2">
                <img
                  src={caseStudyData.additionalImage} // Another image link assumed
                  alt="Project"
                  className="w-full h-auto mb-6 pt-4 pr-12"
                />
              </div> */}
              <div className="w-full md:w-1/2 pt-8 pb-8">
                <strong className="text-2xl text-black">Important Facts</strong>
                <ul className="list-disc pb-2 text-black pl-5">
                  {caseStudyData.importantFacts.map((fact, index) => (
                    <li
                      key={index}
                      className="marker:text-orange-500 marker:text-2xl pt-2 font-semibold"
                    >
                      {fact}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <p className="text-black pb-4">{caseStudyData.paragraph2}</p>
            <p className="text-black pb-8">{caseStudyData.paragraph3}</p>
            <h2 className="text-2xl font-bold text-black mb-4">Results</h2>
            <p className="text-black">{caseStudyData.result}</p>
          </div>
        </div>
        <div className="lg:w-1/3 pr-12">
          <div className="mb-4">
            <div className="border-t-4 rounded-lg border-orange-500 bg-white shadow-xl">
              <ul className="divide-y divide-gray-400 px-6">
                <li className="flex flex-col py-2 pt-8">
                  <div className="flex items-center pl-8">
                    <FontAwesomeIcon
                      icon={faComputer}
                      className="text-orange-500 pr-2 text-xl mr-2"
                    />
                    <strong className="text-xl">Technologies:</strong>
                  </div>
                  <span className="ml-10 pt-4 pb-6 pl-8">
                    {caseStudyData.technologies}
                  </span>
                </li>
                {/* <li className="flex flex-col py-2 pt-4 pl-8">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-orange-500 pr-4 text-xl mr-2"
                    />
                    <strong className="text-xl">Completed:</strong>
                  </div>
                  <span className="ml-10 pt-2 pb-6">
                    {caseStudyData.completed}
                  </span>
                </li> */}
                
                <li className="flex flex-col py-2 pt-4">
                  <div className="flex items-center pl-8 ">
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className="text-orange-500 pr-4 text-xl mr-2"
                    />
                    <strong className="text-xl">Website:</strong>
                  </div>
                  
                  <a
                    href={`https://${caseStudyData.website}`}
                    className="ml-11 pt-2 pb-8 pl-8 hover:text-orange-600 break-words overflow-hidden"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {caseStudyData.website}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyPage;
