import React, { useEffect,useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faLaptop, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import './SolutionArea.css';
import solutionData from './Solution_Data/SolutionData.json'
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for animations

const SolutionArea = () => {
  const [solutions, setSolution] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
    setSolution(solutionData);
  }, []);

  const icons = {
    'faRocket': faRocket,
    'faLaptop': faLaptop,
    'faMoneyBill': faMoneyBill,
  };

  return (
    <div className="pt-32 sm:pt-16 md:pt-8 lg:pt-8 bg-white">
      <div className="container mx-auto text-center" >
        <div className="mb-12 flex items-center justify-center" >
          <img src="https://tarn-react.envytheme.com/img/star-icon.png" alt="Star Icon" className="w-8 h-8 mr-2 rotate-slow" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300"/>
          <h2 className="text-ff5d22 text-[15px] font-semibold" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">Why Choose Us?</h2>
        </div>
        <h3 className="px-4 text-3xl lg:text-4xl font-nunito font-bold max-w-2xl mx-auto" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">We stand out with our commitment to quality, innovation, and customer focus.</h3>
        <p className="text-gray-700 mt-4 max-w-3xl mx-auto" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
        Select us for unparalleled excellence and personalized service. With a track record of success, we're your trusted partner for outstanding results.
        </p>
        <div className="flex flex-wrap justify-center mt-8">
          {solutions.map((solution, index) => (
            <div key={index}
              className="w-full sm:w-1/2 md:w-1/3 p-4"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"  // Delay increases with each card
            >
              <div className="bg-white p-10 rounded-lg shadow-lg min-h-[380px] border border-transparent hover:border-orange-500 transition duration-500 ease-in-out">
                <div className="icon mb-6 text-center text-1lg text-gray-700">
                  <FontAwesomeIcon icon={icons[solution.icon]} size="3x" />
                </div>
                <h4 className="text-2xl font-semibold mb-2">{solution.title}</h4>
                <p className="text-lg text-gray-500 mb-5">{solution.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolutionArea;
